import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import Layout3Columns from "../layout/Layout3Columns"
import { colors } from "../../settings";

const Section = styled.div`
    h2, h3 {
        text-align: center;
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 24px;
    }

    > div {
        > div {
            padding: 15px !important;         
        }

        a.orange {
            color: ${colors.orange};
            display: block;
            text-align: center;
        }

        img {
            display: block;
            margin: 15px auto;
            height: 105px;
        }
    }
`

function WhySection(props) {
    return (
        <Section>
            <h2>
                Why Choose SignMonkey.com
            </h2>

            <Layout3Columns align="top">
                <div>
                    <h3>
                        <Link to="/why/fast-production/" className="orange">
                            5 Day Production *
                        </Link>
                    </h3>
                    <p>
                        While other electric sign manufactures will tell you three or four weeks for manufacturing. SignMonkey.com ships all our signs 
                        within <strong>five working days</strong>.
                    </p>
                </div>
                <div>
                    <h3>
                        <Link to="/why/easy-installation/" className="orange">
                            Easy Install
                        </Link>
                    </h3>
                    <p>
                        All our products are built for easy installation by a general handyman. 
                        Our goal is to help our customers purchase and install their own signs, simply and safely.
                    </p>
                </div>
                <div>
                    <h3>
                        <Link to="/why/pricing/" className="orange">
                            Wholesale Pricing
                        </Link>
                    </h3>
                    <p>
                        Our pricing is <strong>True Wholesale</strong>; this means you are buying directly from a large manufacturer that builds thousands of 
                        electric signs per year. Be assured we have the <strong>highest quality control standards</strong>. When you sell as many signs as we 
                        do, the last thing you want is quality issues.
                    </p>
                </div>
            </Layout3Columns>
        </Section>
    );
}

export default WhySection