import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faPhone } from "@fortawesome/free-solid-svg-icons"

import SEO from "../../components/seo"
import Page from "../../components/layout/Page"

import WhySection from "../../components/sections/WhySection"
import GoogleImage from "../../components/GoogleImage"
import PageHeader from "../../components/layout/PageHeader";
import Layout2Columns from "../../components/layout/Layout2Columns"
import DownloadButton from "../../components/buttons/DownloadButton"
import VideoPreview from "../../components/VideoPreview"

import { devices } from "../../settings"

const InstallPage = styled.div`
  margin-left: 25px;

  @media ${devices.mobile} {
    margin-left: 0px;
  } 

  h3 {
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  }

  img {
    border-radius: 5px;  
  }
`

const WhyInstallPage = () => {
  return (
    <>
      <SEO page="install" title="Fast and Easy Sign Installation Instructions" />
      <Page breadcrumb={[
        { url: "/", title: "Home"},
        { url: "/why", title: "Why SignMonkey"},
        { url: null, title: "Easy Installation" }
      ]}>
        <PageHeader title="Easy Installation" />
        <InstallPage>
            <p>
              All our products are built for easy installation by a general handyman.
            </p>
            <p>
              Our goal is to help our customers purchase and install their own signs, simply and safely.
            </p>
            <p>
                During your installation if you have any questions you can call us anytime at <a href="tel:8008630107"><FontAwesomeSvgIcon icon={faPhone} /> (800) 863-0107</a>
            </p>            
            <p>
                Not all signs are built the same, when you purchase “wholesale” channel letters, you are buying a sign built to be installed by an electrician with knowledge of electrical signs. 
            </p>            
            <p>
                When you buy from SignMonkey.com, you are buying a sign built to be installed by a general handyman or a business owner with general construction skills.
            </p> 

            <br />
            <br />

            <Layout2Columns left={65} right={35}>
              <div>
                <h3>
                    <Link to="/products/face-lit-channel-letters-and-logos/" style={{color:"black"}}>
                        EZLit Signs
                    </Link> 
                </h3>
                <p>
                    You get an installation pattern that shows exactly where each hole should be drilled.
                    Mounting Tabs are provided that make installing fasteners easy and without the need to disassemble the sign.
                    A Plug-n-Play power strip for fast and simple power supply to each letters. This is an exclusive feature of 
                    SignMonkey.com, we have the patent!
                </p>
                <p>
                  <DownloadButton 
                    to={"/documents/installation-ezlit-letters-and-logos/"}
                    imageSrc="SignTypes/Install-Guide-thumb.jpg"
                    label="Installation Instructions"
                  />
                </p>
              </div>
              <div>
                <VideoPreview 
                    video_url="https://www.youtube.com/watch?v=gzBi4yVcetg&amp;rel=0"
                    image_url="files/blog/z-preview.75TSc3VxIy.jpg"
                    page_url="/why/videos/ezlit-letter-installation"
                    size="large"
                />
              </div>
            </Layout2Columns>
            <Layout2Columns left={65} right={35}>
              <div>
                <h3>
                    <Link to="/products/face-lit-channel-letters-and-logos-on-raceway/" style={{color:"black"}}>
                        Raceway Signs
                    </Link> 
                </h3>

                <p>
                    Built of aluminum and plastic to be easy to lift and hold during installation. Raceways have all the 
                    electrical components inside and only require one power connection. You simply drag the crate to the 
                    installation location, take it out of the crate directly to install on the wall, no assembly what so 
                    ever.
                </p>

                <p>
                  <DownloadButton 
                    to={"/documents/installation-raceway-letters-and-logos/"}
                    imageSrc="SignTypes/Install-Guide-thumb.jpg"
                    label="Installation Instructions"
                  />
                </p>
              </div>
              <div>
                <VideoPreview 
                    video_url="https://www.youtube.com/watch?v=ufAxbgqB8Y&amp;rel=0"
                    image_url="images/mounts/rw.installvideo.png"
                    page_url="/why/videos/raceway-installation-video"
                    size="large"
                />
              </div>
            </Layout2Columns>
            <Layout2Columns left={65} right={35}>
              <div>
                <h3>
                    <Link to="/products/lit-shape-sign/" style={{color:"black"}}>
                        Self Contained Signs
                    </Link> 
                </h3>

                <p>
                    All the electrical components inside the sign and require only one electrical connection, just like the 
                    Raceway signs the sign includes our exclusive Sun Control Switch. Just like our EZLit letters, the 
                    signs also include mounting tabs so there is no need to open or disassemble the sign during installation. 
                    Sign goes directly from the crate to install on the wall. No assembly what so ever.
                </p>

                <p>
                  <DownloadButton 
                    to={"/documents/installation-self-contained/"}
                    imageSrc="SignTypes/Install-Guide-thumb.jpg"
                    label="Installation Instructions"
                  />
                </p>
              </div>
              <div>
                  <VideoPreview 
                    video_url="https://www.youtube.com/watch?v=i1i4oAS2sY4&amp;rel=0"
                    image_url="files/B0F49C04-2821-3F46-57D1-4EC5BC8CBBDA.jpg"
                    page_url="/why/videos/american-flag-install---2-minute-video"
                    size="large"
                />

              </div>
            </Layout2Columns>

            <Layout2Columns left={65} right={35}>
              <div>
                <h3>
                    <Link to="/products/face-and-halo-lit-channel-letters-and-logos/" style={{color:"black"}}>
                        Halo Lit Signs
                    </Link> 
                </h3>

                <p>
                  All Halo Lit Sign products are shipped totally complete and ready to install. Their is no 
                  need to assemble or disassemble the sign to install it. The exclusive standoff system and 
                  full size pattern simplify the process. The installation is very straight forward and no 
                  special skills are required.
                </p>
                <p>
                  <DownloadButton 
                    to={"/documents/installation-halo-letters-and-logos/"}
                    imageSrc="SignTypes/Install-Guide-thumb.jpg"
                    label="Installation Instructions"
                  />
                </p>
              </div>
              <div>
                  <VideoPreview 
                    video_url="https://www.youtube.com/watch?v=p9dU_sS-uvg&amp;rel=0"
                    image_url="files/blog/pic-for-install-video.3rLdfpaamh.jpg"
                    page_url="/why/videos/face-and-halo-letter-installation"
                    size="large"
                />

              </div>
            </Layout2Columns>

            <Layout2Columns left={65} right={35}>
              <div>
                <h3>
                    <Link to="/products/dimensional-letters-and-logos/" style={{color:"black"}}>
                      Dimensional Letters & Logo Shapes
                    </Link> 
                </h3>

                <p>
                </p>
                <p>
                  <DownloadButton 
                    to={"/documents/installation-double-sided-tape/"}
                    imageSrc="SignTypes/Install-Guide-thumb.jpg"
                    label="Installation Instructions"
                  />
                </p>
              </div>
              <div>
                  <VideoPreview 
                    video_url="https://www.youtube.com/watch?v=QU8_bLO1RAQ&amp;rel=0"
                    image_url="2020/files/blog/tape_install_video.20230905_100921.jpg"
                    page_url="/why/videos/installation-double-sided-tape"
                    size="large"
                />

              </div>
            </Layout2Columns>
            

            <Layout2Columns left={65} right={35}>
              <div>
                <h3>
                    <Link to="/products/metal-letters-and-logos/" style={{color:"black"}}>
                      Metal Fabricated Letters & Logo Shapes
                    </Link> 
                </h3>

                <p>
                </p>
                <p>
                  <DownloadButton 
                    to={"/documents/installation-stud-mount/"}
                    imageSrc="SignTypes/Install-Guide-thumb.jpg"
                    label="Installation Instructions"
                  />
                </p>
              </div>
              <div>
                  <VideoPreview 
                    video_url="https://www.youtube.com/watch?v=zegm0TjPKlY&amp;rel=0"
                    image_url="2020/files/blog/191792589_6d5b4675_9858_42db_90c6_dd0b4afedd9a.ekCBzvYh62.jpeg"
                    page_url="/why/videos/metal-letter-stud-mount-installation"
                    size="large"
                />

              </div>
            </Layout2Columns>
            
            <br />
            <br />

            <div style={{display: "flex"}}>
              <GoogleImage src="ul-logo-black.png" required lazy alt="UL Logo" style={{width: "100px", height: "35px"}} />
              <div style={{paddingLeft:"15px"}}>
                  Of course all our signs are legal for any place you can install an electrical sign and all our electrical products 
                  have the Underwriter's Laboratory label. Our signs also go through a multi-point inspection process as part of 
                  our manufacturing.
              </div>
            </div>
        </InstallPage>

        <br />
        <br />
        <WhySection />
      </Page>
    </>
  )
}

export default WhyInstallPage